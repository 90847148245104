export default function Header(){
    return (
        <header id="header">
            <div className="inner">
                    <a href="/" className="logo">
                        <span className="symbol"><img src="images/logo.svg" alt="" /></span><span className="title">LangTechHK</span>
                    </a>
                    <nav>
                        <ul>
                            <li><a href="#menu">Menu</a></li>
                        </ul>
                    </nav>
            </div>
        </header>
    );
}