import './menu.css';
import { Link } from 'react-router-dom'
import { UserAuth } from './context/AuthContext';

const Menu = () => {
	const { googleSignIn, user, logOut } = UserAuth();

	  const handleClick = (e) => {
		alert("singout")
		logOut();
	  }


    return (
		
		<nav id="menu">
			<div className="inner">
				<h2>Menu</h2>
				<ul>
					<li><Link to="/">Home</Link></li>	
					<li><Link to="/flashcard">Flashcard</Link></li>
					<li>{user?.displayName}
					{user?.displayName ? (
						<Link to="/" onClick={handleClick}>Logout</Link>
					) : (
						<Link to='/signin'>Sign in</Link>
					)}
					</li>
					<li><Link to="/generic">Generic</Link></li>
					<li><Link to="/elements">Elements</Link></li>
				</ul>
			</div>
			<a className="close" href="#menu">Close</a>
		</nav>

    );
}

export default Menu;