// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5giG-ed5Fe3Tb8VODCmxpLNXA3Abj1yw",
  authDomain: "langtechhk.firebaseapp.com",
  projectId: "langtechhk",
  storageBucket: "langtechhk.appspot.com",
  messagingSenderId: "647704527229",
  appId: "1:647704527229:web:94f9d715b0b481a0224c14",
  measurementId: "G-GG2G6SL98Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);