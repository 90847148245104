import React, { useEffect } from 'react';
import { UserAuth } from './context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    if (user?.displayName != null) {
      navigate(location.pathname);
    }
  }, [user]);

  return (
    <div className="inner"> 
      <h2 className='text-center text-3xl font-bold py-8'>Sign in</h2>
      <div>
        <a href="#" className="button primary icon solid" onClick={handleGoogleSignIn}><i className="fa-brands fa-google"></i> Google</a> <br />
        
      </div>

    </div>
  );
};

export default Signin;