import './home.css'
import { Link } from 'react-router-dom'


export default function Home() {
    return (
        <div className="inner">
            <header>
                <section className="tiles">
                <article>
                    <div className="container"><span className="slogan element">Language</span> <span className="slogan-operator element">&nbsp;+</span></div>
                    <div className="container"><span className="slogan element">Technology</span> <span className="slogan-operator element">=</span></div>
                    <div className="container"><span className="slogan element">Power</span></div><br />
                </article>
                <article >
                    <img className="slogan-picture" src="images/slogan.jpg" />
                </article>
                </section>
                <br />
                <p>Welcome to LangTechHK, your premier destination for effectively learning English through the seamless integration of language and technology. Our innovative platform offers a wide array of tools, resources, and interactive lessons designed to enhance your language skills. Whether you're a beginner starting from scratch or an advanced learner seeking to refine your proficiency, LangTechHK has something for everyone. Join us on this exciting language learning journey and unlock your full potential today!</p>
                <p>歡迎來到LangTechHK，這裡結合了語言和科技，為您提供有效學習英語的工具。我們明白學習一門新語言可能會有挑戰，但是透過我們創新的工具和資源，您將能夠在短時間內掌握英語流利。我們的網站結合了語言和科技的優勢，為您提供全面的學習體驗。無論您是初學者想從頭開始，還是進階學習者希望提升語言能力，LangTechHK都能滿足您的需求。現在就開始您的語言學習之旅吧，立即解鎖更多機會！</p>            
            </header>
        
            <section className="tiles">
                <article className="style1">
                    <span className="image">
                        <img src="images/flashcard.png" alt="" />
                    </span>
                    <Link to="/flashcard">
                        <h2>Flashcard</h2>
                        <div className="content">
                            <p>Master vocabulary effortlessly with LangTechHK's interactive flashcards. Enhance your memorization skills and expand your word bank with our user-friendly tool.</p>
                        </div>
                    </Link>
                </article>
                {/* 
                <article className="style2">
                    <span className="image">
                        <img src="images/pic02.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Lorem</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                
                <article className="style3">
                    <span className="image">
                        <img src="images/pic03.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Feugiat</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style4">
                    <span className="image">
                        <img src="images/pic04.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Tempus</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style5">
                    <span className="image">
                        <img src="images/pic05.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Aliquam</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style6">
                    <span className="image">
                        <img src="images/pic06.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Veroeros</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style2">
                    <span className="image">
                        <img src="images/pic07.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Ipsum</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style3">
                    <span className="image">
                        <img src="images/pic08.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Dolor</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style1">
                    <span className="image">
                        <img src="images/pic09.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Nullam</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style5">
                    <span className="image">
                        <img src="images/pic10.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Ultricies</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style6">
                    <span className="image">
                        <img src="images/pic11.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Dictum</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article>
                <article className="style4">
                    <span className="image">
                        <img src="images/pic12.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Pretium</h2>
                        <div className="content">
                            <p>Sed nisl arcu euismod sit amet nisi lorem etiam dolor veroeros et feugiat.</p>
                        </div>
                    </a>
                </article> */}
            </section>
        </div>
    );
}