import logo from './logo.svg';
import './App.css';
import {Routes, Route} from 'react-router-dom'
import Home from './Home'
import Menu from './Menu';
import Header from './Header';
import Footer from './Footer';
import Generic from './Generic';
import Elements from './Elements';
import Flashcard from './Flashcard';
import Signin from './Signin';
import Protected from './Protected';
import { AuthContextProvider } from './context/AuthContext';


function App() {
  return (
    <div className="is-preload">
		<AuthContextProvider>
			<div id="wrapper">
				<Header />
				<div id="main">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/flashcard" element={<Protected><Flashcard /></Protected>} />
						<Route path='/signin' element={<Signin />} />
						<Route path="/generic" element={<Generic />} />
						<Route path="/elements" element={<Elements />} />
					</Routes>
				</div>
				<Footer />
			</div>
			<Menu />
		</AuthContextProvider>
	</div>
  );
}

export default App;
