import { useEffect } from "react";
import "quill/dist/quill.snow.css";


import Quill from "quill/core";

import Toolbar from "quill/modules/toolbar";
import Snow from "quill/themes/snow";

import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Header from "quill/formats/header";

import { UserAuth } from './context/AuthContext';

import { Link } from 'react-router-dom'


export default function Flashcard() {
    useEffect(() => {
        Quill.register({
            "modules/toolbar": Toolbar,
            "themes/snow": Snow,
            "formats/bold": Bold,
            "formats/italic": Italic,
            "formats/header": Header,
          });          
        new Quill("#editor", {
            theme: "snow",
          });
    }, []);


    const { googleSignIn, user, logOut } = UserAuth();
    const handleSignOut = async () => {
		try {
		  await logOut();
		} catch (error) {
		  console.log(error);
		}
	  };

    return (
        <div className="inner"> 
            <div id="editor">
                <h2>Demo Content</h2>
                <p>Preset build with <code>snow</code> theme, and some common formats.</p>
            </div>
        </div>
    );
}